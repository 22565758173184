import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/layout'; 
import indexStyles from '../styles/index.module.scss'
import { Link } from 'gatsby';

const HomePage = () => {
    return(
        <Layout>
            <div className={indexStyles.content}>
                <h1 className={indexStyles.noPage}>404: Page not found!</h1>
                <Link className ={indexStyles.homeLink} to="/">Regresar a inicio</Link>
            </div>
        </Layout>
    ) 
}
export default HomePage;